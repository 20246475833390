import Vue from 'vue'
import Vuex from 'vuex'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import EventBus from 'eventbus'

import dateFormat from 'dateformat'
import snack from './components/UIComponents/Snackbar/'
import WOFuncs from 'mixins/WOFuncs.vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    stickSidebar: true,
    appData: {},
    path: '',
    origin: '',
    unsavedData: false,
    urls: {
      backend: '',
      frontend: '',
      mssql: ''
    },
    alerts: {
      show: 0,
      message: '',
      classes: [],
      state: '',
      dismiss: ''
    },
    modal: {
      trigger: 0,
      component: '', // name eg. "reset-password"
      size: '', // modal-lg, modal-sm
      content: '',
      classes: '',
      buttons: [
        {
          buttonText: '',
          buttonFunc: '',
          buttonParams: '',
          context: '',
          route: ''
        }
      ]
    },
    sherAuth: '',
    userAuthedData: {},
    waiting: {
      status: 0,
      message: ''
    },
    appIsOutdated: null,
    appDate: '',
    fiscalYear: '',
    employeeData: '',
    employees: [],
    subcontractors: [],
    woInfo: [],
    jobInfo: [],
    jobUdfs: [],
    woPriorityDescriptions: [
      { code: '1', description: 'TBS' },
      { code: '2', description: 'In Progress' },
      { code: '11', description: 'Cancelled' },
      { code: '9', description: 'On Hold' },
      { code: '3', description: 'Need Signature' },
      { code: '10', description: 'Report Sent' },
      { code: '12', description: 'Unresponsive - No Sign Off' },
      { code: '13', description: 'Signed' },
      { code: '4', description: 'Ok to Bill' }
    ],
    woJobDivisions: [
      { code: '1', description: 'Automation Emergency - After Hrs' },
      { code: '10', description: 'Shift Coverage Automation' },
      { code: '11', description: 'Contracting Service - After Hrs' },
      { code: '12', description: 'Post Thermograph Repairs' },
      { code: '2', description: 'Automation Emergency - Reg T' },
      { code: '3', description: 'Contracting Service - Reg T' },
      { code: '4', description: 'Thermograph Inspection' },
      { code: '5', description: 'Contracting Work' },
      { code: '6', description: 'Automation Scheduled Work' },
      { code: '8', description: 'Parts Supply Automation' },
      { code: '9', description: 'Shredit Programming' },
      { code: '13', description: 'Preventative Maintenance' },
      { code: '14', description: 'Technical Services' },
      { code: '16', description: 'Shift Coverage Contracting' },
      { code: '17', description: 'Parts Supply Contracting' },
      { code: '18', description: 'CSA Approval' }
    ],
    jobStages: [
      { code: '1', description: 'TBS' },
      { code: '2', description: 'In Progress' },
      { code: '7', description: 'On Hold' },
      { code: '6', description: 'Cancelled' },
      { code: '3', description: 'Need Signature' },
      { code: '5', description: 'Report Sent' },
      { code: '12', description: 'Unresponsive - No Sign Off' },
      { code: '13', description: 'Signed' },
      { code: '4', description: 'Ok to Bill' }
    ],

    // action managed
    quoteData: [],
    quoteFilterStatus: '',
    quoteFilterShowCancelled: true,
    quoteFilterEmployee: '',
    quoteFilterCaptain: '',
    quoteFilterSalesLead: '',
    quoteFilterQuotedBy: '',
    quoteFilterSalesPerson: '',
    quoteFilterEmployeeAny: [],
    quoteFilterCustomer: '',
    quoteFilterDepartment: '',
    quoteFilterFiscalYear: '',
    customerContacts: [],
    customers: [],
    site_sign_off_contacts: [],
    site_ar_contacts: [],
    woSites: [],
    woAssemble: [], // MSSQL z_wo_summary plus app db data, spectrum tech data
    jobAssemble: [],
    jobLastFetchRef: '',
    woAssembleMeta: {},
    woBaseDetails: [], // WO_HEADER_MC resembles Spectrum WO_ADD_HEADER
    woLaborHours: [],
    appOptions: {},
    vendors: [],
    emCostGroups: [],
    emCostGroupsDetail: [],
    emCostGroupsWithCostCenters: [],
    emCostGroupsWithCostCentersWO: [],
    prDepartment: [],
    vendorContacts: [],
    materialCategories: [],
    arItems: [],
    customerSettingsAR: [],
    vendorPOData: [],
    woRequirementItems: {},
    stateInfo: {}, // helper for various dispatchers to store info about options fetched
    quoteMaterialList: {},
    otherChargesList: [],
    laborChargesList: [],
    viewAsEmployeeId: null,
    equipment: [],
    jobCostCenters: [],
    woCostCenters: [],
    configuredCostCenters: [],
    regions: [],
    selectedDepartment: ''
  },

  getters: {
    stickSidebar(state) {
      return state.stickSidebar
    },
    appData(state) {
      return state.appData
    },
    appIsOutdated(state) {
      return state.appData
    },
    path(state) {
      return state.path
    },
    origin(state) {
      return state.origin
    },
    unsavedData(state) {
      return state.unsavedData
    },
    urls(state) {
      return state.urls
    },
    alerts(state) {
      return state.alerts
    },
    modal(state) {
      return state.modal
    },
    sherAuth(state) {
      return state.sherAuth
    },
    userAuthedData(state) {
      const user = state.userAuthedData
      return user
    },
    waiting(state) {
      return state.waiting
    },
    appDate(state) {
      return state.appDate
    },
    fiscalYear(state) {
      return state.fiscalYear
    },
    employeeData(state) {
      return state.employeeData
    },
    employees(state) {
      return state.employees
    },
    subcontractors(state) {
      return state.subcontractors
    },
    woInfo(state) {
      return state.woInfo
    },
    jobInfo(state) {
      return state.jobInfo
    },
    jobUdfs(state) {
      return state.jobUdfs
    },
    jobAssemble(state) {
      return state.jobAssemble
    },
    jobLastFetchRef(state) {
      return state.jobLastFetchRef
    },
    woPriorityDescriptions(state) {
      return state.woPriorityDescriptions
    },
    woJobDivisions(state) {
      return state.woJobDivisions
    },
    jobStages(state) {
      return state.jobStages
    },
    quoteData(state) {
      return state.quoteData
    },
    customerContacts(state) {
      return state.customerContacts
    },
    customers(state) {
      return state.customers
    },
    site_sign_off_contacts(state) {
      return state.site_sign_off_contacts
    },
    site_ar_contacts(state) {
      return state.site_ar_contacts
    },
    woSites(state) {
      return state.woSites
    },
    woAssemble(state) {
      return state.woAssemble
    },
    woBaseDetails(state) {
      return state.woBaseDetails
    },
    woLaborHours(state) {
      return state.woLaborHours
    },
    appOptions(state) {
      return state.appOptions
    },
    vendors(state) {
      return state.vendors
    },
    emCostGroups(state) {
      return state.emCostGroups
    },
    emCostGroupsDetail(state) {
      return state.emCostGroupsDetail
    },
    emCostGroupsWithCostCenters(state) {
      return state.emCostGroupsWithCostCenters
    },
    emCostGroupsWithCostCentersWO(state) {
      return state.emCostGroupsWithCostCentersWO
    },
    prDepartment(state) {
      return state.prDepartment
    },
    vendorContacts(state) {
      return state.vendorContacts
    },
    vendorPOData(state) {
      return state.vendorPOData
    },
    materialCategories(state) {
      return state.materialCategories
    },
    arItems(state) {
      return state.arItems
    },
    customerSettingsAR(state) {
      return state.customerSettingsAR
    },
    woRequirementItems(state) {
      return state.woRequirementItems
    },
    woOptions(state) {
      return ((((state.woRequirementItems || {}).woOptions || {}).data || {})[0] || {}).admin_options || {}
    },
    stateInfo(state) {
      return state.stateInfo
    },
    quoteMaterialList(state) {
      return state.quoteMaterialList
    },
    otherChargesList(state) {
      return state.otherChargesList
    },
    laborChargesList(state) {
      return state.laborChargesList
    },
    viewAsEmployeeId(state) {
      if (state.userAuthedData.user_role.indexOf('admin') !== -1) {
        return state.viewAsEmployeeId
      }
      return state.userAuthedData.eid
    },
    equipment(state) {
      return state.equipment
    },
    jobCostCenters(state) {
      return state.jobCostCenters
    },
    configuredCostCenters(state) {
      return state.configuredCostCenters
    },
    regions(state) {
      return state.regions
    },
    woCostCenters(state) {
      return state.woCostCenters
    },
    selectedDepartment(state) {
      return state.selectedDepartment
    }
  },

  mutations: {
    appData(state, obj) {
      state.appData = obj
    },
    appIsOutdated(state, obj) {
      state.appIsOutdated = obj
    },
    stickSidebar(state, obj) {
      state.stickSidebar = obj
    },
    urls(state, obj) {
      state.urls = obj
    },
    origin(state, obj) {
      state.origin = obj
    },
    unsavedData(state, obj) {
      state.unsavedData = obj
    },
    path(state, obj) {
      state.path = obj
    },
    alerts(state, obj) {
      state.alerts = obj
    },
    modal(state, obj) {
      state.modal = obj
    },
    sherAuth(state, obj) {
      state.sherAuth = obj
    },
    userAuthedData(state, obj) {
      state.userAuthedData = obj
    },
    waiting(state, obj) {
      state.waiting = obj
    },
    appDate(state, obj) {
      state.appDate = obj
    },
    fiscalYear(state, obj) {
      state.fiscalYear = obj
    },
    employeeData(state, obj) {
      state.employeeData = obj
    },
    employees(state, obj) {
      state.employees = obj
    },
    subcontractors(state, obj) {
      state.subcontractors = obj
    },
    woInfo(state, obj) {
      state.woInfo = obj
    },
    jobInfo(state, obj) {
      state.jobInfo = obj
    },
    jobUdfs(state, obj) {
      state.jobUdfs = obj
    },
    jobAssemble(state, obj) {
      state.jobAssemble = obj
    },
    jobLastFetchRef(state, obj) {
      state.jobLastFetchRef = obj
    },
    quoteData(state, obj) {
      state.quoteData = obj
    },
    quoteFilterStatus(state, val) {
      state.quoteFilterStatus = val
    },
    quoteFilterShowCancelled(state, val) {
      state.quoteFilterShowCancelled = val
    },
    quoteFilterEmployee(state, val) {
      state.quoteFilterEmployee = val
    },
    quoteFilterQuotedBy(state, val) {
      state.quoteFilterQuotedBy = val
    },
    quoteFilterSalesPerson(state, val) {
      state.quoteFilterSalesPerson = val
    },
    quoteFilterCaptain(state, val) {
      state.quoteFilterCaptain = val
    },
    quoteFilterSalesLead(state, val) {
      state.quoteFilterSalesLead = val
    },
    quoteFilterEmployeeAny(state, val) {
      state.quoteFilterEmployeeAny = val
    },
    quoteFilterCustomer(state, val) {
      state.quoteFilterCustomer = val
    },
    quoteFilterDepartment(state, val) {
      state.quoteFilterDepartment = val
    },
    quoteFilterFiscalYear(state, val) {
      state.quoteFilterFiscalYear = val
    },
    customerContacts(state, obj) {
      state.customerContacts = obj
    },
    customers(state, obj) {
      state.customers = obj
    },
    site_sign_off_contacts(state, obj) {
      state.site_sign_off_contacts = obj
    },
    site_ar_contacts(state, obj) {
      state.site_ar_contacts = obj
    },
    woSites(state, obj) {
      state.woSites = obj
    },
    woAssemble(state, obj) {
      state.woAssemble = obj
    },
    woAssembleMeta(state, obj) {
      state.woAssembleMeta = obj
    },
    woLaborHours(state, obj) {
      state.woLaborHours = obj
    },
    woBaseDetails(state, obj) {
      state.woBaseDetails = obj
    },
    appOptions(state, obj) {
      state.appOptions = obj
    },
    vendors(state, obj) {
      state.vendors = obj
    },
    emCostGroups(state, obj) {
      state.emCostGroups = obj
    },
    emCostGroupsDetail(state, obj) {
      state.emCostGroupsDetail = obj
    },
    emCostGroupsWithCostCenters(state, obj) {
      state.emCostGroupsWithCostCenters = obj
    },
    emCostGroupsWithCostCentersWO(state, obj) {
      state.emCostGroupsWithCostCentersWO = obj
    },
    prDepartment(state, obj) {
      state.prDepartment = obj
    },
    vendorContacts(state, obj) {
      state.vendorContacts = obj
    },
    vendorPOData(state, obj) {
      state.vendorPOData = obj
    },
    materialCategories(state, obj) {
      state.materialCategories = obj
    },
    arItems(state, obj) {
      state.arItems = obj
    },
    customerSettingsAR(state, obj) {
      state.customerSettingsAR = obj
    },
    woRequirementItems(state, obj) {
      state.woRequirementItems = obj
    },
    stateInfo(state, obj) {
      if (obj.key) {
        const keyObj = {
          value: obj.value,
          expiry: obj.expiry
        }
        state.stateInfo[obj.key] = keyObj
      }
    },
    quoteMaterialList(state, obj) {
      state.quoteMaterialList = obj
    },
    laborChargesList(state, obj) {
      state.laborChargesList = obj
    },
    otherChargesList(state, obj) {
      state.otherChargesList = obj
    },
    viewAsEmployeeId(state, obj) {
      state.viewAsEmployeeId = obj
    },
    equipment(state, obj) {
      state.equipment = obj
    },
    jobCostCenters(state, obj) {
      state.jobCostCenters = obj
    },
    configuredCostCenters(state, obj) {
      state.configuredCostCenters = obj
    },
    regions(state, obj) {
      state.regions = obj
    },
    woCostCenters(state, data) {
      state.woCostCenters = data
    },
    selectedDepartment(state, data) {
      state.selectedDepartment = data
    }
  },

  actions: {
    getAppData(context, refresh) {
      // unauth func - provides time and app version for front
      return new Promise((resolve, reject) => {
        let setTime = context.state.appData.setTime
        // if expires was less than 20 mins ago
        if (!refresh && setTime && (setTime + 60 * 20) * 1000 > Date.now()) {
          resolve()
          return
        }
        appFuncs
          .shRequest({ action: 'get_app_data' })
          .then(data => {
            context.commit('appData', data)
            context.commit('fiscalYear', data.fiscalYear)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    stickSidebar(context, val) {
      if (val) {
        localStorage.setItem('stickSidebar', true)
      } else {
        localStorage.removeItem('stickSidebar')
      }
      context.commit('stickSidebar', val)
    },

    getStickSidebar(context) {
      let val = localStorage.getItem('stickSidebar')
      context.commit('stickSidebar', val)
      return val
    },

    setSherAuth(context, data) {
      let sherAuth = data || appFuncs.storageGet('sherAuth') || {}
      if (data) {
        appFuncs.storageSave('sherAuth', data, 3600)
      }
      context.commit('sherAuth', sherAuth)
    },

    setUserAuthedData(context, data) {
      let authedUserData = data || appFuncs.storageGet('userAuthedData') || {}
      if (data) {
        appFuncs.storageSave('userAuthedData', authedUserData, 3600)
      }
      context.commit('userAuthedData', authedUserData)
    },

    clearLogin(context) {
      context.commit('userAuthedData', {
        eid: '',
        user_login: '',
        fname: '',
        mname: '',
        lname: '',
        user_role: []
      })
      appFuncs.storageClear('userAuthedData')
      context.commit('sherAuth', {})
      appFuncs.storageClear('sherAuth')
    },

    unsavedDataClear(context) {
      context.commit('unsavedData', false)
    },

    unsavedDataSet(context) {
      context.commit('unsavedData', true)
    },

    getQuotesOLD(context, params) {
      return new Promise(async (resolve, reject) => {
        // make sure any non values match 'all' since that's what endpoint provides
        let fiscalYear = context.state.quoteFilterFiscalYear

        if (!fiscalYear) {
          const date = new Date()
          let year = date.getFullYear()
          const monthNumber = date.getMonth()
          if (monthNumber > 8) {
            // greater than september (indexed scale)
            year += 1
          }
          fiscalYear = year.toString()
        }

        let refresh = (params && params.refresh) || ''
        let statuses = context.state.quoteFilterStatus || ''
        let id = (params && params.id) || ''

        let cacheName = fiscalYear.toString() + statuses.toString() + id
        if (!refresh && context.state.stateInfo.quoteData && context.state.stateInfo.quoteData.value === cacheName) {
          resolve()
          return
        }

        const data = {
          action: 'get_quote_list',
          fiscal_year: fiscalYear === 'all' ? '' : fiscalYear,
          statuses,
          id
        }

        const quoteFetch = appFuncs.shRequest(data)
        const customerFetch = context.dispatch('getCustomers')

        await Promise.all([quoteFetch, customerFetch])
          .then(data => {
            let quotes = Array.isArray(data[0]) ? data[0] : []
            let customers = context.getters.customers || []
            if (quotes.length && customers.length) {
              for (var i = 0; i < quotes.length; i++) {
                for (var ci = 0; ci < customers.length; ci++) {
                  if (quotes[i].customer_code == customers[ci].Customer_Code) {
                    quotes[i].customer_name = customers[ci].Name
                    continue
                  }
                }
              }
            }
            context.commit('stateInfo', { key: 'quoteData', value: cacheName })
            context.commit('quoteData', quotes)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getQuotes(context, params) {
      return new Promise(async (resolve, reject) => {
        // make sure any non values match 'all' since that's what endpoint provides
        let fiscalYear = context.state.quoteFilterFiscalYear

        if (!fiscalYear) {
          const date = new Date()
          let year = date.getFullYear()
          const monthNumber = date.getMonth()
          if (monthNumber > 8) {
            // greater than september (indexed scale)
            year += 1
          }
          fiscalYear = year.toString()
        }

        let refresh = (params && params.refresh) || ''
        let statuses = context.state.quoteFilterStatus || ''
        let id = (params && params.id) || ''

        let cacheName = `${fiscalYear.toString()} + ${statuses.toString()} + ${id} + min`
        if (!refresh && context.state.stateInfo.quoteData && context.state.stateInfo.quoteData.value === cacheName) {
          resolve()
          return
        }

        const data = {
          action: 'get_quotes',
          fiscal_year: fiscalYear === 'all' ? '' : fiscalYear,
          statuses,
          id
        }

        const quoteFetch = appFuncs
          .shRequest(data)
          .then(data => {
            context.commit('stateInfo', { key: 'quoteData', value: cacheName })
            context.commit('quoteData', data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getCustomerContacts(context, refresh) {
      return new Promise((resolve, reject) => {
        let data = context.state.customerContacts
        if (!refresh && data.length) {
          resolve()
          return
        }

        const cacheName = refresh ? 'noCache' : 'short'
        appFuncs
          .shRequest({ action: 'sf_get_customer_contacts', cacheName })
          .then(data => {
            for (var i = 0; i < data.length; i++) {
              data[i].sfId = data[i].Id
              // helper for Bhtable
              data[i].id = data[i].Id
              data[i].Status = data[i].Inactive__c ? 'Inactive' : 'Active'
            }

            data = data.sort((a, b) => {
              if (a.Name < b.Name) return -1
              return 1
            })

            context.commit('customerContacts', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getCustomers(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.customers.length) {
          resolve()
          return
        }

        // start async which waits for first nested await, then combines retrieved data in for loop. Nested await does simultaneous requests for two endpoint fetches
        ;(async () => {
          let customersSpectrum = []
          let customersSf = []

          await Promise.all([
            (async () =>
              appFuncs
                .shRequest({ action: 'get_customers' })
                .then(data => {
                  customersSpectrum = data
                })
                .catch(res => {
                  console.log(res)
                  snack.open('Problem getting Spectrum customer info', 'error')
                  // reject since this is depenadant data, reject here gets passed to main promise
                  reject(res)
                }))(),

            (async () =>
              appFuncs
                .shRequest({ action: 'sf_get_customer_accounts' })
                .then(data => {
                  customersSf = data
                })
                .catch(res => {
                  snack.open('Problem getting SF customer account info', 'error')
                  console.log(res)
                }))()
          ])

          for (var i = 0; i < customersSpectrum.length; i++) {
            for (var csf = 0; csf < customersSf.length; csf++) {
              if (customersSf[csf].Customer_Code__c == customersSpectrum[i].Customer_Code) {
                customersSpectrum[i].sfId = customersSf[csf].Id
                customersSpectrum[i].Name = customersSf[csf].Name
                customersSpectrum[i].RealOwnerEmployeeCode__c = customersSf[csf].RealOwnerEmployeeCode__c
              }
            }
          }
          context.commit('customers', customersSpectrum)
          resolve()
        })()
      })
    },

    async sitesAccReceivableContacts(context, refresh) {
      if (!refresh && context.state.site_ar_contacts.length) {
        return
      }

      try {
        const site_ar_contacts = await appFuncs.shRequest({ action: 'get_site_app_data' })
        context.commit('site_ar_contacts', site_ar_contacts)
      } catch (error) {
        snack.open('Problem getting Spectrum customer info', 'error')
      }
    },

    async sitesSignOffContacts(context, refresh) {
      if (!refresh && context.state.site_sign_off_contacts.length) {
        return
      }

      try {
        const site_sign_off_contacts = await appFuncs.shRequest({ action: 'get_site_app_data' })
        context.commit('site_sign_off_contacts', site_sign_off_contacts)
      } catch (error) {
        snack.open('Problem getting Spectrum customer info', 'error')
      }
    },

    getWOSites(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.woSites.length) {
          resolve()
          return
        }

        // start async which waits for first nested await, then combines retrieved data in for loop. Nested await does simultaneous requests for two endpoint fetches
        ;(async () => {
          let sitesSpectrum = []
          let sitesSf = []
          await Promise.all([
            (async () =>
              appFuncs
                .shRequest({ action: 'get_wo_site_address' })
                .then(data => {
                  sitesSpectrum = data
                })
                .catch(res => {
                  console.log(res)
                  snack.open('Problem getting Spectrum customer info', 'error')
                  // reject since this is depenadant data, reject here gets passed to main promise
                  reject(res)
                }))(),

            (async () =>
              appFuncs
                .shRequest({ action: 'sf_get_wo_sites' })
                .then(data => {
                  sitesSf = data
                })
                .catch(res => {
                  snack.open('Problem getting SF customer account info', 'error')
                  console.log(res)
                }))()
          ])

          for (var i = 0; i < sitesSpectrum.length; i++) {
            for (var ssf = 0; ssf < sitesSf.length; ssf++) {
              sitesSpectrum[i].sfId = null
              sitesSpectrum[i].Name = null
              if (sitesSf[ssf].Site_ID__c && sitesSf[ssf].Site_ID__c == sitesSpectrum[i].Ship_To_ID) {
                sitesSpectrum[i].sfId = sitesSf[ssf].Id
                sitesSpectrum[i].Name = sitesSf[ssf].Name
                sitesSpectrum[i].RealOwnerEmployeeCode__c = sitesSf[ssf].RealOwnerEmployeeCode__c
                break
              }
              // define empty prop for compat elsewhere
            }
          }
          context.commit('woSites', sitesSpectrum)
          resolve()
        })()
      })
    },

    getEmployees(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.employees.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_employee_data'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context
              .dispatch('setEmployees', data)
              .then(() => {
                resolve()
              })
              .catch(res => {
                reject(res)
              })
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getSubcontractors(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.subcontractors.length) {
          resolve()
          return
        }
        appFuncs.getTableData(
          'SubcontactorList',
          result => {
            if (result.data) {
              let data = result.data.sort((a, b) => {
                if (!a.Name || !b.Name) return
                if (a.Name.trim() < b.Name.trim()) return -1
                if (a.Name.trim() > b.Name.trim()) return 1
                return 0
              })
              context.commit('subcontractors', data)
              resolve()
            } else {
              reject('Problem getting subcontractors')
              console.log(result)
            }
          },
          null,
          refresh
        )
      })
    },

    setEmployees(context, data) {
      return new Promise((resolve, reject) => {
        if (!Array.isArray(data)) {
          reject(new Error('Problem with received employee data'))
          return
        }

        data = data.sort((a, b) => {
          if (!a.Employee_Name || !b.Employee_Name) return
          return a.Employee_Name.toLowerCase() > b.Employee_Name.toLowerCase() ? 1 : -1
        })

        for (var i = data.length - 1; i >= 0; i--) {
          data[i].id = data[i].Employee_Code
          data[i].Phone = commonFuncs.methods.formatPhoneNumber(data[i].Phone)
          data[i].Employee_Mobile_Phone = commonFuncs.methods.formatPhoneNumber(data[i].Employee_Mobile_Phone)
          data[i].Employee_Extension = commonFuncs.methods.formatPhoneNumber(data[i].Employee_Extension)
        }
        context.commit('employees', data)
        resolve()
      })
    },

    updateEmployee(context, employeeData) {
      return new Promise((resolve, reject) => {
        if (typeof employeeData !== 'object' || !employeeData.Employee_Code) {
          reject(new Error('Employee cannot be updated with data provided.'))
          return
        }
        const params = {
          action: 'update_employee_personal_data',
          employee_code: employeeData.Employee_Code,
          data: employeeData
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            // update state data with app employee changes
            let employees = context.state.employees
            for (var i = employees.length - 1; i >= 0; i--) {
              if (employees[i].Employee_Code == employeeData.Employee_Code) {
                employees[i] = employeeData
              }
            }
            context
              .dispatch('setEmployees', employees)
              .then(() => {
                resolve()
              })
              .catch(res => {
                reject(res)
              })
            resolve()
          })
          .catch(res => {
            reject(new Error(res.message))
          })
      })
    },

    getWoAssemble(context, input) {
      return new Promise((resolve, reject) => {
        let refresh = input
        let woNumber = null,
          min = null,
          max = null,
          customerCode = null,
          all = null,
          missing_po = null

        if (typeof input === 'object') {
          refresh = input.refresh
          woNumber = input.woNumber
          min = input.min
          max = input.max
          customerCode = input.customerCode
          all = input.all ? 1 : 0
          missing_po = input.missing_po ? 1 : 0
        }

        let existing = context.state.woAssemble

        // if requested WO exists already mark to prevent requesting again
        let woExists = false
        if (woNumber && existing.length) {
          woExists = existing.find(itm => {
            return itm.WO_Number == woNumber
          })
        }

        if (!customerCode && !min && !max && !refresh && existing.length && existing.length > 4 && !all) {
          if ((woNumber && woExists) || !woNumber) {
            resolve()
            return
          }
        }

        const params = {
          action: 'assemble_wip_wo_data',
          woNumber,
          customerCode,
          min,
          max,
          all,
          missing_po
        }

        appFuncs
          .shRequest(params)
          .then(data => {
            for (var i = data.length - 1; i >= 0; i--) {
              let pd = WOFuncs.data().woPriorityDescriptions.find(itm => {
                return itm.code == data[i].Priority_Code
              })

              data[i].Priority_Description = pd ? pd.description : ''

              let ds = WOFuncs.data().woDispatchStatuses.find(itm => {
                return itm.code == data[i].Dispatch_Status_Code
              })

              data[i].Dispatch_Description = ds ? ds.description : ''

              // validate report_send_log is an array. then extract the object containing the latest unix time from the time property, then convert to date string
              data[i].Last_Report_Send_Date = ''
              if (Array.isArray(data[i].report_send_log) && data[i].report_send_log.length) {
                let latest = data[i].report_send_log.reduce((a, b) => {
                  return a.time > b.time ? a : b
                })

                const reportSendLog = Array.isArray(data[i].report_send_log) ? data[i].report_send_log : []

                // items are saved within an array of arrays, so flatten to single array
                let flattenedReportSendLog = [].concat(...reportSendLog)

                if (Array.isArray(flattenedReportSendLog) && flattenedReportSendLog.length > 0) {
                  let latest = flattenedReportSendLog.reduce(
                    (a, b) => {
                      return a.time && b.time && a.time > b.time ? a : b
                    },
                    { time: 0 }
                  ) // Initialize with an object having time as 0

                  if (latest && latest.time) {
                    let lastReportSendDate = new Date(latest.time * 1000)
                    data[i].Last_Report_Send_Date = dateFormat(lastReportSendDate, 'yyyy-mm-dd')
                  }
                }
              }

              if (data[i]['Ordered_Date']) {
                data[i]['ordered_date_formatted'] = dateFormat(Date.parse(data[i]['Ordered_Date']), 'yyyy-mm-dd')
              }

              if (data[i]['Complete_Date']) {
                data[i]['completed_date_formatted'] = dateFormat(Date.parse(data[i]['Complete_Date']), 'yyyy-mm-dd')
              }

              if (data[i]['sign_off']) {
                if (data[i]['sign_off'].toLowerCase() === 'yes') {
                  data[i]['WO_Recieved'] = '<i data-filterby="a" class="ti-check text-success" style="font-size:larger"></i>'
                } else if (data[i]['sign_off'] === 'no') {
                  data[i]['WO_Recieved'] = '<span data-filterby="b"></span>'
                }
              } else {
                data[i]['WO_Recieved'] = ''
              }

              let filterVal = ''

              let Priority_Description = data[i]['Priority_Description']
              filterVal = Priority_Description || 'z'
              if (data[i]['Priority_Code'] == 10) {
                Priority_Description = `<span class="text-danger font-weight-bold">${Priority_Description} (${data[i].Last_Report_Send_Date})</span>`
              }

              data[i]['Priority_Description_Link'] = {
                action: 'openWOWipMain',
                title: '<i data-filterby="' + filterVal + '" class="ti-new-window"></i>&nbsp;' + Priority_Description + '<span></span>'
              }

              let WO_Recieved = data[i]['WO_Recieved']
              data[i]['WO_Recieved'] = {
                action: 'openWOWipMain',
                centered: 1,
                title: WO_Recieved
              }

              let status_note = data[i]['status_note'] || ''
              filterVal = status_note || 'z'
              data[i]['status_note'] = {
                action: 'openWOWipNotes',
                title: '<i data-filterby="' + filterVal + '" class="ti-new-window"></i>&nbsp;<span>' + status_note + '</span>'
              }

              // validate report_send_log is an array. then extract the object containing the latest unix time from the time property, then convert to date string
              data[i].Last_Report_Send_Date = ''
              data[i].First_Report_Send_Date = ''
              if (Array.isArray(data[i].report_send_log) && data[i].report_send_log.length) {
                let latest = data[i].report_send_log.reduce((a, b) => {
                  return a.time > b.time ? a : b
                })

                const reportSendLog = Array.isArray(data[i].report_send_log) ? data[i].report_send_log : []

                // items are saved within an array of arrays, so flatten to single array
                let flattenedReportSendLog = [].concat(...reportSendLog)

                if (Array.isArray(flattenedReportSendLog) && flattenedReportSendLog.length > 0) {
                  let latest = flattenedReportSendLog.reduce(
                    (a, b) => {
                      return a.time && b.time && a.time > b.time ? a : b
                    },
                    { time: 0 }
                  ) // Initialize with an object having time as 0
                  let oldest = flattenedReportSendLog.reduce(
                    (a, b) => {
                      return a.time && b.time && a.time < b.time ? a : b
                    },
                    { time: 0 }
                  ) // Initialize with an object having time as 0

                  if (latest && latest.time) {
                    let lastReportSendDate = new Date(latest.time * 1000)
                    data[i].Last_Report_Send_Date = dateFormat(lastReportSendDate, 'yyyy-mm-dd')
                  }

                  if (oldest && oldest.time) {
                    let firstReportSendDate = new Date(oldest.time * 1000)
                    data[i].First_Report_Send_Date = dateFormat(firstReportSendDate, 'yyyy-mm-dd')
                  }
                }
              }
            }

            data = data.sort((a, b) => {
              return b.WO_Number - a.WO_Number
            })

            context.commit('woAssemble', data)

            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getJobUdfs(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.jobUdfs.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_all_job_udfs'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('jobUdfs', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getWOLaborHours(context, refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh && context.state.woLaborHours.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_wo_labor_hours_all'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('woLaborHours', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getAppOptions(context, refresh) {
      return new Promise((resolve, reject) => {
        const appOptions = context.getters.appOptions
        const empty = Object.keys(appOptions).length === 0 && appOptions.constructor === Object
        if (!refresh && !empty) {
          resolve()
          return
        }
        const params = {
          action: 'get_app_options'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('appOptions', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getVendors(context, refresh) {
      return new Promise((resolve, reject) => {
        const vendors = context.getters.vendors
        if (!refresh && vendors && vendors.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_vendor_data'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            // 1 for use server, or false

            // add an id mainly for use with bhtable
            for (var i = data.length - 1; i >= 0; i--) {
              data[i].id = i
            }

            context.commit('vendors', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getEmCostGroups(context, refresh) {
      return new Promise((resolve, reject) => {
        const emCostGroups = context.getters.emCostGroups
        if (!refresh && emCostGroups && emCostGroups.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_em_cost_groups_data'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            const using = ['ADMIN', 'AUTOMATION', 'GTA BRANCH', 'LONDON BRA', 'WTP', 'ENGINEER']
            data = data.filter(itm => {
              return using.includes(itm.Cost_Group_Code)
            })

            context.commit('emCostGroups', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getEmCostGroupsDetail(context, refresh) {
      return new Promise((resolve, reject) => {
        const emCostGroupsDetail = context.getters.emCostGroupsDetail
        if (!refresh && emCostGroupsDetail && emCostGroupsDetail.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_em_cost_groups_detail_data'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('emCostGroupsDetail', Object.values(data))
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getEmCostGroupsWithCostCenters(context, { refresh = false, type = 'job' } = {}) {
      return new Promise((resolve, reject) => {
        if (type === 'job') {
          const emCostGroupsWithCostCenters = context.getters.emCostGroupsWithCostCenters
          if (!refresh && emCostGroupsWithCostCenters && emCostGroupsWithCostCenters.length) {
            resolve()
            return
          }
        } else {
          const emCostGroupsWithCostCentersWO = context.getters.emCostGroupsWithCostCentersWO
          if (!refresh && emCostGroupsWithCostCentersWO && emCostGroupsWithCostCentersWO.length) {
            resolve()
            return
          }
        }
        const params = {
          action: 'get_em_cost_groups_with_cost_centers',
          type
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            if (type === 'job') {
              context.commit('emCostGroupsWithCostCenters', data)
            } else {
              context.commit('emCostGroupsWithCostCentersWO', data)
            }
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getPrDepartment(context, refresh) {
      return new Promise((resolve, reject) => {
        const prDepartment = context.getters.prDepartment
        if (!refresh && prDepartment && prDepartment.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_pr_department_data'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            const using = ['ADMIN', 'EST', 'MGT', 'SALES']
            data = data.filter(itm => {
              return using.includes(itm.Department_Code)
            })

            context.commit('prDepartment', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getVendorContacts(context, refresh) {
      return new Promise(async (resolve, reject) => {
        if (!context.getters.vendors.length) {
          await context.dispatch('getVendors')
        }

        const vendorContacts = context.getters.vendorContacts
        if (!refresh && vendorContacts && vendorContacts.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_vendor_contacts'
        }
        appFuncs
          .shRequest(params)
          .then(contacts => {
            for (var i = 0; i < contacts.length; i++) {
              contacts[i].Vendor_Name = contacts[i].Vendor_Code
              let vendor = context.getters.vendors.find(itm => {
                return itm.Vendor_Code == contacts[i].Vendor_Code
              })
              if (vendor && vendor.Vendor_Name) {
                contacts[i].Vendor_Name = vendor.Vendor_Name
              }
            }

            context.commit('vendorContacts', contacts)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getVendorPOData(context, refresh) {
      return new Promise((resolve, reject) => {
        const vendorPOData = context.getters.vendorPOData
        if (!refresh && vendorPOData && vendorPOData.length) {
          resolve()
          return
        }
        const params = {
          action: 'vendor_po_reports_get_entries'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('vendorPOData', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getMaterialCategories(context, refresh) {
      return new Promise((resolve, reject) => {
        const materialCategories = context.getters.materialCategories
        if (!refresh && materialCategories && materialCategories.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_material_categories'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('materialCategories', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getARItems(context, refresh) {
      return new Promise((resolve, reject) => {
        const arItems = context.getters.arItems
        if (!refresh && arItems && arItems.length) {
          resolve()
          return
        }
        const params = {
          action: 'get_ar_items'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('arItems', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getCustomerSettingsAR(context, refresh) {
      return new Promise((resolve, reject) => {
        const customerSettingsAR = context.getters.customerSettingsAR
        if (!refresh && customerSettingsAR && customerSettingsAR.length) {
          resolve()
          return
        }
        const params = {
          action: 'accounts_receivable_customer_get_entries'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('customerSettingsAR', data)
            resolve()
          })
          .catch(res => {
            reject(res)
          })
      })
    },

    getWoRequirementItems(context, obj) {
      return new Promise((resolve, reject) => {
        const woRequirementItems = context.getters.woRequirementItems
        if (woRequirementItems.fetching || !obj.woid || (!obj.refresh && !appFuncs.isEmpty(woRequirementItems))) {
          resolve()
          return
        }
        const params = {
          action: 'get_wo_requirement_items',
          wo_number: obj.woid
        }
        EventBus.$emit('setWaiting', {
          name: params.action,
          message: 'Getting WO Requirements'
        })
        context.commit('woRequirementItems', { fetching: true })
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('woRequirementItems', data)
            resolve()
          })
          .catch(res => {
            this.$snack.open('Problem fetching WO required items', 'error')
            reject()
          })
          .finally(() => {
            EventBus.$emit('stopWaiting', params.action)
          })
      })
    },

    getJobAssemble(context, params) {
      let refresh = (params || {}).refresh
      let notStatus = (params || {}).not_status || ''
      let jobLastFetchRef = context.state.jobLastFetchRef
      let jobNumber = (params || {}).job_number
      let lastFetch = jobNumber ? jobNumber : notStatus

      let lastStatusDifferent = lastFetch !== jobLastFetchRef

      return new Promise(async (resolve, reject) => {
        if (!lastStatusDifferent && !refresh && context.state.jobAssemble.length) {
          resolve()
          return
        }

        let statusCodes = []

        const udfFetch = context.dispatch('getJobUdfs', refresh)
        const jobFetch = appFuncs.shRequest({
          action: 'assemble_wip_job_data',
          not_status: notStatus,
          job_number: jobNumber
        })

        let allData = []
        try {
          allData = await Promise.all([udfFetch, jobFetch])
        } catch (err) {
          reject(err)
          return
        }

        let data = allData[1] // jobs
        const udfs = store.state.jobUdfs || []

        if (data && data.Job_Number) {
          data = [data]
        }

        for (var i = data.length - 1; i >= 0; i--) {
          data[i].quote_number = ''
          data[i].sales_person = ''
          for (let ji = 0; ji < udfs.length; ji++) {
            if (data[i].Job_Number && udfs[ji].Job_Number == data[i].Job_Number) {
              data[i].quote_number = typeof udfs[ji].quote_number === 'string' || typeof udfs[ji].quote_number === 'number' ? udfs[ji].quote_number : ''
              data[i].sales_person = typeof udfs[ji].sales_person === 'string' || typeof udfs[ji].sales_person === 'number' ? udfs[ji].sales_person : ''
              break
            }
          }

          if (data[i]['Last_Cost_Date']) {
            data[i]['Last_Cost_Date_formatted'] = dateFormat(Date.parse(data[i]['Last_Cost_Date']), 'yyyy-mm-dd')
          }
          if (data[i]['Last_Bill_Date']) {
            data[i]['Last_Bill_Date_formatted'] = dateFormat(Date.parse(data[i]['Last_Bill_Date']), 'yyyy-mm-dd')
          }
          if (typeof data[i]['Sign_Off_Status'] === 'string') {
            if (data[i]['Sign_Off_Status'].toLowerCase() === 'yes') {
              data[i]['Job_Recieved'] = '<i data-filterby="a" class="ti-check text-success" style="font-size:larger"></i>'
            } else if (data[i]['Sign_Off_Status'].toLowerCase() === 'no') {
              data[i]['Job_Recieved'] = '<span data-filterby="b"></span>'
            }
          }
          let ds = store.getters.jobStages.find(itm => {
            return itm.code == data[i].Stage
          })
          data[i].Stage_Description = ds ? ds.description : ''

          // validate report_send_log is an array. then extract the object containing the latest unix time from the time property, then convert to date string
          data[i].Last_Report_Send_Date = ''
          data[i].First_Report_Send_Date = ''
          if (Array.isArray(data[i].report_send_log) && data[i].report_send_log.length) {
            let latest = data[i].report_send_log.reduce((a, b) => {
              return a.time > b.time ? a : b
            })

            const reportSendLog = Array.isArray(data[i].report_send_log) ? data[i].report_send_log : []

            // items are saved within an array of arrays, so flatten to single array
            let flattenedReportSendLog = [].concat(...reportSendLog)

            if (Array.isArray(flattenedReportSendLog) && flattenedReportSendLog.length > 0) {
              let latest = flattenedReportSendLog.reduce(
                (a, b) => {
                  return a.time && b.time && a.time > b.time ? a : b
                },
                { time: 0 }
              ) // Initialize with an object having time as 0
              let oldest = flattenedReportSendLog.reduce(
                (a, b) => {
                  return a.time && b.time && a.time < b.time ? a : b
                },
                { time: 0 }
              ) // Initialize with an object having time as 0

              if (latest && latest.time) {
                let lastReportSendDate = new Date(latest.time * 1000)
                data[i].Last_Report_Send_Date = dateFormat(lastReportSendDate, 'yyyy-mm-dd')
              }

              if (oldest && oldest.time) {
                let firstReportSendDate = new Date(oldest.time * 1000)
                data[i].First_Report_Send_Date = dateFormat(firstReportSendDate, 'yyyy-mm-dd')
              }
            }
          }
          data[i].Job_Number = parseInt(data[i].Job_Number)
        }

        data = data.sort((a, b) => {
          return b.Job_Number - a.Job_Number
        })

        context.commit('jobAssemble', data)
        context.commit('jobLastFetchRef', lastFetch)
        resolve()
      })
    },

    getQuoteMaterialList(context, data) {
      return new Promise((resolve, reject) => {
        if (!data || (!data.id && !data.quoteId && !data.quoteNumber)) {
          const id = ((context.state.quoteMaterialList || {}).materialList || {}).id || null
          if (!id) {
            resolve()
            return
            reject(new Error('Problem loading quote material list data'))
            return
          }
          data = { id }
        }
        const params = {
          action: 'get_material_quote_list_with_material_data',
          id: data.id,
          quote_id: data.quoteId,
          quote_number: data.quoteNumber
        }
        appFuncs
          .shHttp(params)
          .then(res => {
            if (res.status === 'success') {
              context.commit('quoteMaterialList', res.data)
              EventBus.$emit('updatedQuoteMaterialList')
              resolve()
            } else {
              console.log(res)
              reject(new Error(res.message))
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getJobCostCenters(context) {
      return new Promise((resolve, reject) => {
        const jobCostCenters = context.state.jobCostCenters || null
        if (jobCostCenters && jobCostCenters.length) {
          resolve()
          return
        }

        const params = {
          action: 'get_job_cost_centers'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('jobCostCenters', data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getConfiguredCostCenters(context) {
      return new Promise((resolve, reject) => {
        const configuredCostCenters = context.state.configuredCostCenters || null
        if (configuredCostCenters && configuredCostCenters.length) {
          resolve()
          return
        }

        const params = {
          action: 'get_configured_job_cost_centers'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('configuredCostCenters', data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getRegions(context) {
      return new Promise((resolve, reject) => {
        const regions = context.state.regions || null
        if (regions && regions.length) {
          resolve()
          return
        }

        context.commit('regions', ['fetching'])

        const params = {
          action: 'get_global_options',
          group: 'cost_center_regions'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('regions', data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    updateQuoteMaterialList(context, options) {
      /*
      three update options:
      a. background update - update data without refreshing store data - needed to prevent impacting unsaved data
        - might need to have server function which before saving refetches the saved data and only saves the data provided
      b. silent update - update data, commit to store and sent update event - needed for refreshing data in various components
      c. full update - same as silent but has waiting spinner until data is reloaded to protect data integrity

      */

      return new Promise((resolve, reject) => {
        options = options || {}
        const copyToNew = options.copyToNew
        let fields = context.state.quoteMaterialList.materialList

        if (!fields || !fields.id) {
          reject('Problem updating quote material list')
          return
        }

        // add changes from any optoins.fields supplied
        for (var prop in options.fields) {
          if (!options.fields.hasOwnProperty(prop)) continue
          fields[prop] = options.fields[prop]
        }

        const noRefetch = options.noRefetch

        if (copyToNew) {
          fields.status = 'open'
          fields.id = null
        }

        delete fields.added_items

        const params = {
          action: 'upsert_material_quote_list',
          id: fields.id,
          data: fields
        }

        appFuncs
          .shHttp(params)
          .then(async res => {
            if (res.status === 'success') {
              let id = res.id || fields.id
              if (res.data && !options.background) {
                context.commit('quoteMaterialList', res.data)
                EventBus.$emit('updatedQuoteMaterialList')
              }
              resolve()
            } else {
              console.log(res)
              snack.open(res.message || 'Problem saving list', 'error')
              reject(res)
            }
          })
          .catch(data => {
            console.log(data)
            snack.open(data.message || 'Problem saving material list', 'error')
            reject()
          })
      })
    },

    getOtherCharges(context, options) {
      return new Promise((resolve, reject) => {
        const refresh = options && options.refresh ? true : false
        if (context.state.otherChargesList.length && !refresh) {
          resolve()
          return
        }
        const params = {
          action: 'get_bom_other_charges'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('otherChargesList', data)
            resolve()
          })
          .catch(data => {
            snack.open(data.message || 'Problem getting other charges.', 'error')
            reject()
          })
      })
    },

    getLaborCharges(context, options) {
      return new Promise((resolve, reject) => {
        const refresh = options && options.refresh ? true : false
        if (context.state.laborChargesList.length && !refresh) {
          resolve()
          return
        }
        const params = {
          action: 'get_bom_labor_types'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            context.commit('laborChargesList', data)
            resolve()
          })
          .catch(data => {
            snack.open(data.message || 'Problem getting labor charges.', 'error')
            reject()
          })
      })
    },

    getEquipment(context, options) {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_equipment'
        }

        appFuncs.shHttp(params).then(result => {
          if (result.status === 'success') {
            let raw = result.data
            let formatted = []
            for (let i = 0; i < raw.length; i++) {
              if (raw[i].Equipment_Type == '2' || raw[i].Equipment_Status !== 'A') continue
              let desc = typeof raw[i].Description === 'string' && raw[i].Description ? raw[i].Description : ''
              let license = typeof raw[i].License_Number === 'string' && raw[i].License_Number ? ' - ' + raw[i].License_Number : ''
              let model = typeof raw[i].Equipment_Model === 'string' && raw[i].Equipment_Model ? ' ' + raw[i].Equipment_Model : ''
              let make = typeof raw[i].Equipment_Make === 'string' && raw[i].Equipment_Make ? ' - ' + raw[i].Equipment_Make : ''
              let year = typeof raw[i].Year === 'string' && raw[i].Year ? ' - ' + raw[i].Year : ''
              formatted.push({
                Name: desc + license + make + model + year,
                Code: raw[i].Equipment_Code
              })
            }
            context.commit('equipment', formatted)
            resolve()
          } else {
            reject(result.message || 'Problem fetching equipment', 'error')
          }
        })
      })
    },

    async checkAppVersion(context) {
      await context.dispatch('setUserAuthedData')
      const data = context.getters.userAuthedData
      const backendAppVersion = (data && data.appVersion) || null
      const frontendAppVersion = typeof APP_VERSION !== 'undefined' ? APP_VERSION : null
      const versionsObj = { frontendAppVersion, backendAppVersion }
      if (backendAppVersion && frontendAppVersion) {
        if (backendAppVersion !== frontendAppVersion) {
          context.commit('appIsOutdated', true)
          // console.log('app version is not up to date', versionsObj)
        } else {
          // console.log('version is up to date', versionsObj)
          context.commit('appIsOutdated', false)
        }
      } else {
        // console.log('missing appVersion data', versionsObj)
        context.commit('appIsOutdated', null)
      }
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  var devUrls = {
    backend: 'http://localhost:5001/dist/',
    frontend: 'http://localhost:8082/',
    mssql: 'https://dev.mysheridan.ca/'
  }
  store.commit('urls', devUrls)
}

if (process.env.NODE_ENV === 'production') {
  var domain = window.location.hostname
  if (domain.indexOf('dev.mysheridan.ca') !== -1) {
    var base = 'https://dev.mysheridan.ca/'
  } else if (domain.indexOf('mysheridan.ca') !== -1) {
    var base = 'https://mysheridan.ca/'
  }
  var prodUrls = {
    backend: base,
    frontend: base,
    mssql: base
  }
  store.commit('urls', prodUrls)
}

export default store
