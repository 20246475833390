<template>
  <div class="rich-text-inline">
    <div v-if="!isEditing" class="formatted-content ql-editor" v-html="sanitizedContent" @click="startEditing"></div>

    <div v-else class="editor-wrapper ql-container ql-snow" :ref="editorWrapperRef">
      <div :id="editorWrapperRef" style="position: relative" @keyup.enter="addItem"></div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'
import Quill from 'quill'
import QuillTableBetter from 'quill-table-better'
import 'quill/dist/quill.snow.css'
import 'quill-table-better/dist/quill-table-better.css'

Quill.register({ 'modules/table-better': QuillTableBetter }, true)

export default {
  name: 'RichTextInline',

  components: {
    Quill
  },

  props: {
    content: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Click to edit...'
    },
    readonly: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      quill: null,
      isEditing: false,
      editedContent: '',
      sanitizedContent: '',
      editorWrapperRef: 'editor' + window.crypto.randomUUID()
    }
  },

  watch: {
    content: {
      immediate: true,
      handler(newContent) {
        this.sanitizedContent = DOMPurify.sanitize(newContent)
      }
    },

    isEditing(val) {
      if (val) {
        setTimeout(() => {
          const editor = document.querySelector(`#${this.editorWrapperRef} .ql-editor`)
          if (editor) editor.focus(), window.getSelection()?.selectAllChildren(editor), window.getSelection()?.collapseToEnd()
          this.$emit('setEditing', true)
        })
      }
    }
  },

  methods: {
    addItem(event) {
      this.$emit('keyup:enter', event)
    },

    startEditing() {
      if (this.readonly) return
      this.isEditing = true
      this.editedContent = this.content

      this.$nextTick(() => {
        this.initializeQuill()
        setTimeout(() => {
          document.addEventListener('click', this.handleClickOutside)
          this.$bus.$on('saveRichTextContent', this.saveContent)
        })
      })
    },

    cancelEditing() {
      if (this.quill) {
        this.quill.off('text-change', this.handleBlur)
        this.quill = null
      }
      this.isEditing = false
      this.editedContent = this.content
    },

    saveContent() {
      const sanitized = DOMPurify.sanitize(this.editedContent)
      this.$emit('update:content', sanitized)
      this.$emit('save', sanitized)
      this.isEditing = false
      document.removeEventListener('click', this.handleClickOutside)
      this.$bus.$off('saveRichTextContent', this.saveContent)
    },

    initializeQuill() {
      const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], ['table-better']]

      this.quill = new Quill(`#${this.editorWrapperRef}`, {
        modules: {
          toolbar: toolbarOptions,
          table: false,
          'table-better': {
            language: 'en_US',
            menus: ['column', 'row', 'merge', 'cell', 'delete'],
            toolbarTable: true
          },
          keyboard: {
            bindings: QuillTableBetter.keyboardBindings
          }
        },
        theme: 'snow',
        placeholder: this.placeholder
      })

      this.quill.root.innerHTML = this.editedContent
      this.quill.root.parentElement.style.maxHeight = '100%'
      this.quill.root.parentElement.style.overflow = 'unset'
      this.quill.on('text-change', this.handleBlur)
      // this.quill.root.addEventListener('mousedown', this.handleTableMouseDown)
    },

    handleTableMouseDown(event) {
      const cell = event.target.closest('.ql-table-better')
      const tableMenuContainer = document.querySelector('.ql-table-menus-container')

      if (cell) {
        document.querySelector('.editor-wrapper').append(tableMenuContainer)
        tableMenuContainer.addEventListener('mousedown', this.handleMenuMouseDown)
      }
    },

    handleMenuMouseDown() {
      setTimeout(() => {
        const tablePropertiesForm = document.querySelector('.ql-table-properties-form')
        if (tablePropertiesForm) {
          tablePropertiesForm.classList.add('properties-form')
          document.querySelector('.editor-wrapper').append(tablePropertiesForm)
        }
      }, 500)
    },

    handleBlur() {
      this.editedContent = this.quill.root.innerHTML
    },

    handleClickOutside(event) {
      const editor = this.$refs[this.editorWrapperRef]
      if (editor && !editor.contains(event.target)) {
        this.saveContent()
      }
    }
  },

  beforeDestroy() {
    if (this.quill) {
      this.quill.off('text-change', this.handleBlur)
      this.quill = null
      this.$bus.$off('saveRichTextContent', this.saveContent)
    }
  }
}
</script>

<style lang="scss">
.rich-text-inline {
  p {
    margin: 0;
  }

  ol {
    padding-left: 20px;
  }

  @for $i from 1 through 8 {
    .ql-indent-#{$i} {
      padding-left: calc($i * 3em + 20px);
    }
    li[data-list='bullet'] {
      margin-left: 20px;
      padding-left: 0 !important;
      list-style-type: disc;
      &.ql-indent-#{$i} {
        margin-left: calc($i * 3em + 20px);
        @if $i == 1 or $i == 4 or $i == 7 {
          list-style-type: circle;
        } @else if $i == 2 or $i == 5 or $i == 8 {
          list-style-type: square;
        }
      }

      .ql-ui:before {
        content: none !important;
      }
    }
    li[data-list='ordered'] {
      margin-left: 5px;
      padding-left: 0 !important;
      list-style-type: none;
      counter-increment: list-0;
      counter-set: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-0, decimal) '. ';
      }
      &.ql-indent-#{$i} {
        margin-left: calc($i * 3em + 5px);
        counter-increment: list-#{$i};

        counter-set: list-#{$i + 1} list-#{$i + 2} list-#{$i + 3} list-#{$i + 4} list-#{$i + 5} list-#{$i + 6} list-#{$i + 7} list-#{$i + 8} list-#{$i + 9};

        &:before {
          content: counter(list-#{$i}, #{if($i % 3 == 1, 'lower-alpha', if($i % 3 == 2, 'lower-roman', 'decimal'))}) '. ';
        }
      }
      .ql-ui:before {
        content: none !important;
      }
    }
  }

  .editor-wrapper {
    &:focus-within {
      .ql-toolbar.ql-snow {
        border: 2px solid #428bca;
        border-bottom: 1px solid #ccc;
        border-radius: 3px 3px 0 0;
      }

      .ql-container.ql-snow {
        border: 2px solid #428bca;
        border-top: none;
        border-radius: 0 0 3px 3px;
      }
    }

    .ql-table-menus-container,
    .ql-table-properties-form {
      // top: 50px !important;
      // right: 28px;
      // left: unset !important;

      .ql-table-dropdown-list {
        li {
          cursor: pointer;
        }
      }
    }

    // .ql-table-menus-container {
    //   &::before,
    //   &::after {
    //     content: none;
    //   }
    // }

    .ql-table-properties-form {
      //   visibility: hidden;

      //   &.properties-form {
      //     visibility: visible;
      //   }

      .properties-form-row:first-of-type {
        display: none;
      }
    }

    .ql-operate-line-container {
      display: none !important;
    }
  }

  .formatted-content {
    min-height: 22px;
    .ql-cell-selected-after,
    .ql-cell-selected::after,
    .ql-cell-focused::after {
      border: none;
      background-color: unset;
    }
  }
}
</style>

<style lang="scss" scoped>
.rich-text-inline {
  .formatted-content {
    padding: 0 5px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    // min-height: 40px;
    word-wrap: break-word;
    max-height: 300px;
    overflow: auto;

    &:hover {
      background: #e4e4e4;
    }
  }

  .editor-actions {
    padding: 8px 12px;
    text-align: right;

    button {
      margin-left: 8px;
    }
  }
}
</style>
