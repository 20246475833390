<template>
  <div>
    <div v-if="!openedWriteUpWindow">
      <button class="btn btn-no-radius btn-pip btn-blue" @click="editSystems" v-if="!readonly">Add / Edit System</button>

      <button class="btn btn-no-radius btn-pip btn-orange" @click="openInNewTab" v-if="!readonly && showOpenInNewTab && !isOpenInDifferentTab()">Open in New Window</button>

      <br /><br />

      <h4><strong>Scope of Work</strong></h4>
      <tabulator-table :table-data="systems" :table-columns="systemsTableList" :allow-new-row="false" @colClick="systemsTableClick" :table-fit="'fitColumns'" :row-formatter="systemRowFormatter" />

      <br />

      <div class="row writeup-headers">
        <div class="col col-sm-12 col-md-6">
          <button xclass="btn btn-full-width" class="btn btn-no-radius btn-pip btn-blue btn-full-width btn-pip-lg" @click="addCustomHeader()" v-if="!readonly" style="margin-bottom: 20px">
            Add Header
          </button>
        </div>
        <div class="col col-sm-12 col-md-6">
          <button xclass="btn btn-full-width" class="btn btn-no-radius btn-pip btn-blue btn-full-width btn-pip-lg" @click="addHeader()" v-if="!readonly" style="margin-bottom: 20px">
            Add Header from Template
          </button>
        </div>
      </div>

      <div v-for="(h, hi) in currentNotes" :key="hi">
        <quote-write-up-note-header
          ref="quoteWriteUpNoteHeader"
          v-if="h.id"
          :notes="Array.isArray(h.notes) ? h.notes : []"
          :table-content="h.table"
          :header-name="h.name"
          :header-id="h.id"
          @updateNote="updateNote($event, h.id, false)"
          :show-buttons="h.id != 1"
          @addCustomItem="addNote(h.id, h.name, null, true)"
          @addCustomItemBelow="addCustomItemBelow($event, h.id)"
          @cancelNoteEdit="cancelNoteEdit($event, h.id)"
          @deleteNote="deleteNote(h.id, $event)"
          @addFileItem="addHeader(h.id)"
          :systems="systems"
          :readonly="readonly"
          @saveHeaderName="saveHeaderName(h.id, $event)"
          @deleteHeader="deleteHeader(h.id)"
          @moveUp="moveHeaderUp(h.id)"
          @moveDown="moveHeaderDown(h.id)"
          @moveNoteUp="moveNoteUp(h.id, $event)"
          @moveNoteDown="moveNoteDown(h.id, $event)"
          @saveTableNote="saveTableNote(h.id, $event)"
          :sortable="parseInt(h.sort) < 999 && parseInt(h.sort) > 1"
        />
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <file-upload
            v-if="quoteData && quoteData.quote_incr"
            label="Quote Image Files"
            :path="'quote-uploads/' + quoteData.quote_incr + '/' + quoteData.revision + '/quote-image-attachments'"
            :subdir="''"
            :zip-depth="-1"
            name="Quote Image Files"
            :type-group="'image'"
            :key="quoteData.quote_incr + quoteData.revision"
          />
        </div>
        <div class="col-sm-12 col-md-6">
          <file-upload
            v-if="quoteData && quoteData.quote_incr"
            label="Quote Attachments"
            :path="'quote-uploads/' + quoteData.quote_incr + '/' + quoteData.revision + '/quote-attachments'"
            :subdir="''"
            :zip-depth="-1"
            name="Quote Attachments"
            :key="quoteData.quote_incr + quoteData.revision"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p>Write up has been opened in another tab, please check or re-open this quote window.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import QuoteWriteUpNoteHeader from 'components/Dashboard/Quotes/QuoteWriteUpNoteHeader'
import { diff, detailedDiff } from 'deep-object-diff'
import TableEditor from 'components/UIComponents/TableEditor'
import FileUpload from 'components/UIComponents/FileUpload'

export default {
  name: 'QuoteWriteUp',
  data() {
    return {
      systems: [],
      committedNotes: [],
      currentNotes: [],
      currentTables: [], // each table as exists in current state, not saved
      committedTables: [], // each table when user has
      loadedNotes: [],
      movedHeaders: [],
      lettersArray: {},
      openedWriteUpWindow: null,
      reloadListener: null,
      writeUpWindowExternalCloseInterval: null
    }
  },

  props: {
    quoteMaterialListId: {
      type: [Number, String],
      required: true
    },
    readonly: {
      type: Boolean,
      default: true
    },
    showOpenInNewTab: {
      type: Boolean,
      default: false
    }
  },

  components: {
    TabulatorTable,
    QuoteWriteUpNoteHeader,
    TableEditor,
    FileUpload
  },

  computed: {
    ...mapGetters(['quoteMaterialList', 'urls', 'userAuthedData']),

    systemsTableList() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          field: 'number',
          title: 'Item #',
          width: 100,
          headerSort: false
        },
        {
          field: 'name',
          title: 'Details',
          headerSort: false
        },
        {
          field: 'addButton',
          title: 'Add Note',
          width: 80,
          visible: !this.readonly,
          headerSort: false,
          formatter: () => {
            return '<i class="fa fa-plus is-link table-add-icon-button"></i>'
          },
          align: 'center'
        }
      ]
    },

    setShowOpenInNewTab() {
      if (!this.showOpenInNewtab) {
        return false
      }
      const params = Object.assign({}, this.$route.params)
      if (params.openTab) {
        return false
      }
      return true
    },

    quoteData() {
      return (this.quoteMaterialList || {}).quoteData || {}
    }
  },

  methods: {
    isOpenInDifferentTab() {
      if (this.openedWriteUpWindow && this.openedWriteUpWindow.closed !== true) {
        return true
      }

      // if (this.openedWriteUpWindow && this.openedWriteUpWindow.location.href.indexOf('quote-write-up') > -1) {
      //   return true;
      // }
      return false
    },

    getData(refresh) {
      return new Promise((resolve, reject) => {
        if (!refresh || !this.quoteMaterialListId) {
          let data = this.quoteMaterialList.materialList
          let notes = data && Array.isArray(data.system_notes) ? data.system_notes : []
          notes = notes.filter(itm => itm.id)
          this.committedNotes = [...notes]
          this.loadedNotes = [...notes]
          this.setQuoteMaterialList()
          resolve()
          return
        }

        this.$bus.$emit('setWaiting', { name: 'quoteMaterialList', message: 'Getting Quote Write Up' })
        this.$store
          .dispatch('getQuoteMaterialList', { id: this.quoteMaterialListId })
          .then(() => {
            let data = this.quoteMaterialList.materialList
            let notes = Array.isArray(data.system_notes) ? data.system_notes : []
            notes = notes.filter(itm => itm.id)
            this.committedNotes = [...notes]
            this.loadedNotes = [...notes]
            this.setQuoteMaterialList()
            resolve()
          })
          .catch(res => {
            this.$snack.open(res.message || 'Cannot fetch systems', 'error')
            reject(res)
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'quoteMaterialList')
          })
      })
    },

    async openInNewTab() {
      await this.saveAllNotes()

      if (this.openedWriteUpWindow) {
        this.openedWriteUpWindow.close()
      }
      this.openedWriteUpWindow = window.open(`${this.urls.frontend}#/quote-write-up/${this.quoteMaterialListId}`, 'Quote Write Up', '_blank')
      window.addEventListener('beforeunload', this.closePopoupWindowListner)

      this.writeUpWindowExternalCloseInterval = setInterval(() => {
        if (this.openedWriteUpWindow && this.openedWriteUpWindow.closed === true) {
          this.openedWriteUpWindow = null
          clearInterval(this.writeUpWindowExternalCloseInterval)
          this.getData(true)
        }
      }, 1000)
    },

    closePopoupWindowListner(event) {
      if (this.openedWriteUpWindow) {
        this.openedWriteUpWindow.close()
      }
    },

    windowCloseListener(event) {
      if (this.hasUnsavedNotes()) {
        event.preventDefault()
        event.returnValue = null
      }
    },

    hasUnsavedChanges() {
      const unsaved = diff(this.committedNotes, this.loadedNotes)
      if (!this.isObjEmpty(unsaved)) {
        return true
      }
      return false
    },

    hasUnsavedNotes() {
      // need to compare loaded notes with current notes
      let hasEdits = false
      let noteHeaders = Array.isArray(this.$refs.quoteWriteUpNoteHeader) ? this.$refs.quoteWriteUpNoteHeader : []
      for (let i = 0; i < noteHeaders.length; i++) {
        if (noteHeaders[i]) {
          if (noteHeaders[i].hasAnyEditsBeforeUnload()) {
            hasEdits = true
          }
        }
      }

      return hasEdits || Boolean(this.movedHeaders.length) || !this.isSortedAscending(this.lettersArray)
    },

    addHeader(headerId) {
      // show modal which adds header
      // function called by modal contains header obj
      // addNote(headerId, headerName)

      this.$Modal({
        parent: this,
        name: 'QuoteWriteUpAddHeader', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl, full
        hideClose: false,
        modalFloat: true,
        component: () => import('components/Dashboard/Quotes/QuoteWriteUpAddHeader.vue'),
        props: {
          callback: obj => {
            if (obj.type == 'header') {
              this.addSystemNote(obj.h, null, '', true)
            } else if (obj.type == 'note') {
              const addToHeader = headerId ? { id: headerId } : obj.h
              this.addSystemNote(addToHeader, null, obj.n.name, true)
            } else if (obj.type == 'headerNotes') {
              if (obj.n.length) {
                obj.n.map(e => {
                  this.addSystemNote(obj.h, null, e, true)
                })
              } else {
                this.addSystemNote(obj.h, null, '', true)
              }
            }
          }
        }
      })
    },

    addCustomHeader() {
      let id = this.randomCharacterString(10)
      let sort = this.getNextHeaderSort(this.currentNotes)
      this.addSystemNote({ id, name: 'New Custom Header', sort, notes: [] }, null, '', true)
      this.$bus.$emit('QuoteWriteUpHeaderChange')
    },

    addNote(headerId, headerName) {
      this.addSystemNote({ id: headerId, name: headerName }, null, '')
    },

    deleteNote(headerId, n) {
      let allNotes = JSON.parse(JSON.stringify(this.currentNotes))
      let headerIndex = allNotes.findIndex(itm => {
        return itm.id == headerId
      })
      let headerNotes = allNotes[headerIndex] && allNotes[headerIndex].notes ? allNotes[headerIndex].notes : []
      let i = headerNotes.findIndex(itm => {
        if (n.si) {
          return itm.sn == n.sn && itm.sl == n.sl
        }
        return itm.sl == n.sl
      })
      let notes = allNotes[headerIndex].notes
      notes.splice(i, 1)

      // sort by system then letter
      notes.sort((a, b) => {
        if (a.sn === b.sn) {
          return a.sl < b.sl ? -1 : 1
        } else {
          return parseFloat(a.sn) < parseFloat(b.sn) ? -1 : 1
        }
      })

      // re-index sort letter, start at A for next system
      for (let ni = 0; ni < notes.length; ni++) {
        if (!notes[ni - 1] || notes[ni].sn !== notes[ni - 1].sn) {
          notes[ni].sl = 'a'
        } else {
          notes[ni].sl = this.incrementLetter(notes[ni - 1].sl)
        }
      }
      allNotes[headerIndex].notes = notes
      this.currentNotes = allNotes

      // delete sepearately in saved note
      let savedNotes = JSON.parse(JSON.stringify(this.committedNotes))
      for (let sni = 0; sni < savedNotes.length; sni++) {
        if (savedNotes[sni].id == headerId) {
          let notes = savedNotes[sni].notes ? savedNotes[sni].notes : []
          let savedNoteIndex = notes.findIndex(itm => {
            if (n.sn) {
              return itm.sn == n.sn && itm.sl == n.sl
            }
            return itm.sl == n.sl
          })
          if (savedNoteIndex !== -1) {
            notes.splice(savedNoteIndex, 1)

            // sort by system then letter
            notes.sort((a, b) => {
              if (a.sn === b.sn) {
                return a.sl < b.sl ? -1 : 1
              } else {
                return parseFloat(a.sn) < parseFloat(b.sn) ? -1 : 1
              }
            })

            // re-index sort letter, start at A for next system
            for (let ni = 0; ni < notes.length; ni++) {
              if (!notes[ni - 1] || notes[ni].sn != notes[ni - 1].sn) {
                notes[ni].sl = 'a'
              } else {
                notes[ni].sl = this.incrementLetter(notes[ni - 1].sl)
              }
            }
          }
          savedNotes[sni].notes = notes
        }
      }
      this.committedNotes = savedNotes
    },

    cancelNoteEdit(n, headerId) {
      let allNotes = JSON.parse(JSON.stringify(this.committedNotes))
      let savedHeader = allNotes.find(itm => {
        return itm.id == headerId
      })
      if (!savedHeader || !Array.isArray(savedHeader.notes)) return
      let savedNote = savedHeader.notes.find(itm => {
        return itm.si == n.si && itm.sl == n.sl
      })
      if (!savedNote) return

      let currentNotes = this.currentNotes
      for (let i = 0; i < currentNotes.length; i++) {
        if (currentNotes[i].id == headerId) {
          if (!currentNotes[i].notes) return
          for (let ni = 0; ni < currentNotes[i].notes.length; ni++) {
            if (currentNotes[i].notes[ni].si == n.si && currentNotes[i].notes[ni].sl == n.sl) {
              currentNotes[i].notes[ni].t = savedNote.t
              delete currentNotes[i].notes[ni].editing
            }
          }
        }
      }
      this.currentNotes = currentNotes
    },

    isSortedAscending(obj) {
      return Object.values(obj).every(arr => arr.every((val, index, array) => index === 0 || val >= array[index - 1]))
    },

    keepOrderOfNotes(headerId, si, letterIndex, type, notesCount) {
      headerId = si ? si : headerId
      if (!this.lettersArray[headerId]) {
        this.lettersArray[headerId] = Array.from({ length: notesCount }, (_, i) => String.fromCharCode(97 + i))
      }

      if (type === 'up' && letterIndex > 0) {
        ;[this.lettersArray[headerId][letterIndex], this.lettersArray[headerId][letterIndex - 1]] = [this.lettersArray[headerId][letterIndex - 1], this.lettersArray[headerId][letterIndex]]
      } else if (type === 'down' && letterIndex < this.lettersArray[headerId].length - 1) {
        ;[this.lettersArray[headerId][letterIndex], this.lettersArray[headerId][letterIndex + 1]] = [this.lettersArray[headerId][letterIndex + 1], this.lettersArray[headerId][letterIndex]]
      }
    },

    moveNoteUp(headerId, obj) {
      let si = obj.si
      let letter = obj.sl
      let allNotes = JSON.parse(JSON.stringify(this.currentNotes))
      let headerIndex = allNotes.findIndex(itm => {
        return itm.id == headerId
      })
      let headerNotes = JSON.parse(JSON.stringify(allNotes[headerIndex].notes))
      let systemNotes = headerNotes.filter(itm => itm.si == si)
      this.keepOrderOfNotes(headerId, si, obj.index, 'up', systemNotes.length)

      for (let i = 0; i < systemNotes.length; i++) {
        if (systemNotes[i].sl === letter) {
          if (i > 0) {
            let thisNoteLetter = JSON.parse(JSON.stringify(systemNotes[i].sl))
            let upperNoteLetter = JSON.parse(JSON.stringify(systemNotes[i - 1].sl))
            systemNotes[i - 1].sl = thisNoteLetter
            systemNotes[i].sl = upperNoteLetter
          }
          break
        }
      }

      // add modified system notes back to headerNotes, then re-sort
      let allOtherNotes = headerNotes.filter(itm => itm.si != si)
      headerNotes = allOtherNotes.concat(systemNotes)

      headerNotes.sort((a, b) => (a.sl > b.sl ? 1 : -1))
      allNotes[headerIndex].notes = headerNotes
      allNotes.sort((a, b) => (a.sort > b.sort ? 1 : -1))
      this.currentNotes = allNotes
      this.committedNotes = allNotes
    },

    moveNoteDown(headerId, obj) {
      let si = obj.si
      let letter = obj.sl
      let allNotes = JSON.parse(JSON.stringify(this.currentNotes))
      let headerIndex = allNotes.findIndex(itm => {
        return itm.id == headerId
      })
      let headerNotes = JSON.parse(JSON.stringify(allNotes[headerIndex].notes))
      let systemNotes = headerNotes.filter(itm => itm.si == si)
      this.keepOrderOfNotes(headerId, si, obj.index, 'down', systemNotes.length)

      for (let i = 0; i < systemNotes.length; i++) {
        if (systemNotes[i].sl === letter) {
          if (i < systemNotes.length - 1) {
            let thisNoteLetter = JSON.parse(JSON.stringify(systemNotes[i].sl))
            let lowerNoteLetter = JSON.parse(JSON.stringify(systemNotes[i + 1].sl))
            systemNotes[i].sl = lowerNoteLetter
            systemNotes[i + 1].sl = thisNoteLetter
          }
          break
        }
      }
      // add modified system notes back to headerNotes, then re-sort
      let allOtherNotes = headerNotes.filter(itm => itm.si != si)
      headerNotes = allOtherNotes.concat(systemNotes)

      headerNotes.sort((a, b) => (a.sl > b.sl ? 1 : -1))
      allNotes[headerIndex].notes = headerNotes
      allNotes.sort((a, b) => (a.sort > b.sort ? 1 : -1))
      this.currentNotes = allNotes
      this.committedNotes = allNotes
    },

    saveHeaderName(headerId, headerName) {
      let allNotes = this.currentNotes
      for (let i = 0; i < allNotes.length; i++) {
        if (allNotes[i].id == headerId) {
          allNotes[i].name = headerName
        }
      }
      this.committedNotes = allNotes
      this.saveNotes()
    },

    deleteHeader(headerId) {
      if (!confirm("Are you sure you'd like to delete this header including any contained notes?")) return
      let allNotes = this.currentNotes
      for (var i = allNotes.length - 1; i >= 0; i--) {
        if (allNotes[i].id == headerId) {
          allNotes.splice(i, 1)
        }
      }
      this.committedNotes = allNotes
      this.saveNotes()
    },

    moveHeaderUp(headerId) {
      const currentIndex = this.currentNotes.findIndex(e => e.id === headerId)
      let allNotes = this.currentNotes
      for (let i = 0; i < allNotes.length; i++) {
        if (allNotes[i].id == headerId) {
          if (i > 0) {
            let thisSort = parseInt(allNotes[i].sort)
            let upperSort = parseInt(allNotes[i - 1].sort)
            if (thisSort === 999 || upperSort === 999 || thisSort === 1 || upperSort === 1) continue // dont sort hard coded sort values
            allNotes[i - 1].sort = thisSort
            allNotes[i].sort = upperSort
          }
        }
      }

      if (this.currentNotes[currentIndex - 1]) {
        const movedHeaderIndex = this.movedHeaders.findIndex(e => e === headerId + 'down')
        if (movedHeaderIndex !== -1) {
          this.movedHeaders.splice(movedHeaderIndex, 1)
        } else {
          this.movedHeaders.push(headerId + 'up')
        }

        const movedHeaderIndex2 = this.movedHeaders.findIndex(e => e === this.currentNotes[currentIndex - 1].id + 'up')
        if (movedHeaderIndex2 !== -1) {
          this.movedHeaders.splice(movedHeaderIndex2, 1)
        } else {
          this.movedHeaders.push(this.currentNotes[currentIndex - 1].id + 'down')
        }
      }

      this.setNotes()
      this.committedNotes = allNotes
      this.$bus.$emit('QuoteWriteUpHeaderChange')
      // this.saveNotes();
    },

    moveHeaderDown(headerId) {
      const currentIndex = this.currentNotes.findIndex(e => e.id === headerId)
      let allNotes = this.currentNotes
      for (let i = 0; i < allNotes.length; i++) {
        if (allNotes[i].id == headerId) {
          if (i + 1 < allNotes.length) {
            let thisSort = parseInt(allNotes[i].sort)
            let lowerSort = parseInt(allNotes[i + 1].sort)
            if (thisSort === 999 || lowerSort === 999 || thisSort === 1 || lowerSort === 1) continue // dont sort hard coded sort values
            allNotes[i + 1].sort = thisSort
            allNotes[i].sort = lowerSort
          }
        }
      }

      if (this.currentNotes[currentIndex + 1]) {
        const movedHeaderIndex = this.movedHeaders.findIndex(e => e === headerId + 'up')
        if (movedHeaderIndex !== -1) {
          this.movedHeaders.splice(movedHeaderIndex, 1)
        } else {
          this.movedHeaders.push(headerId + 'down')
        }

        const movedHeaderIndex2 = this.movedHeaders.findIndex(e => e === this.currentNotes[currentIndex + 1].id + 'down')
        if (movedHeaderIndex2 !== -1) {
          this.movedHeaders.splice(movedHeaderIndex2, 1)
        } else {
          this.movedHeaders.push(this.currentNotes[currentIndex + 1].id + 'up')
        }
      }

      this.setNotes()
      this.committedNotes = allNotes
      this.$bus.$emit('QuoteWriteUpHeaderChange')
      // this.saveNotes();
    },

    setNotes() {
      let allNotes = JSON.parse(JSON.stringify(this.currentNotes))
      let systems = JSON.parse(JSON.stringify(((this.quoteMaterialList || {}).materialList || {}).systems || []))
      systems = Array.isArray(systems) ? systems : []

      systems = systems.filter(itm => {
        return itm.id !== 'default'
      })

      // sort headers in order
      allNotes = Array.isArray(allNotes) ? allNotes : []
      allNotes = allNotes.sort((a, b) => {
        let aOrder = parseFloat(a.sort) || 0
        let bOrder = parseFloat(b.sort) || 0
        if (aOrder < bOrder) return -1
        if (aOrder > bOrder) return 1
        return 0
      })

      for (let hi = 0; hi < allNotes.length; hi++) {
        let notes = Array.isArray(allNotes[hi].notes) ? allNotes[hi].notes : []

        // need to convert note si id to system number, then sort based on that.
        for (let i = 0; i < notes.length; i++) {
          for (let si = 0; si < systems.length; si++) {
            if (systems[si].id == notes[i].si) {
              notes[i].sn = systems[si].number
            }
          }
        }

        // if any notes do not contain sl or t is undefined, remove the note
        for (let i = notes.length - 1; i >= 0; i--) {
          if (notes[i].t === undefined) {
            notes.splice(i, 1)
          }
        }

        notes = notes.sort((a, b) => {
          if (a.sl < b.sl) return -1
          if (a.sl > b.sl) return 1
          return 0
        })

        notes = notes.sort((a, b) => {
          if (parseFloat(a.sn) < parseFloat(b.sn)) return -1
          if (parseFloat(a.sn) > parseFloat(b.sn)) return 1
          return 0
        })

        allNotes[hi].notes = notes
      }

      // Re-indexing sort values, skipping 999 and 1
      let sortIndex = 2 // Starting from 2
      allNotes.forEach(note => {
        if (parseInt(note.sort) !== 999 && parseInt(note.sort) !== 1) {
          note.sort = sortIndex++
        }
      })

      this.currentNotes = allNotes
      this.fixNoteLetters()
    },

    systemRowFormatter(row) {
      var data = row.getData()
      let rowEl = row.getElement()
    },

    editSystems() {
      this.$Modal({
        parent: this,
        name: 'BOMSystemEdit', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/Quotes/BOMSystemEdit'),
        props: {
          listId: this.quoteMaterialListId,
          systems: ((this.quoteMaterialList || {}).materialList || {}).systems || []
        }
      })
    },

    systemsTableClick(obj) {
      if (this.readonly) return
      let id = ((obj || {}).cell || {}).id || 0
      let itm_id = ((obj || {}).row || {}).itm_id || 0
      let field = ((obj || {}).cell || {}).field || 0
      const systemId = id
      switch (field) {
        case 'addButton':
          this.addSystemNote({ id: '1', name: 'Details and Specifications', sort: 1 }, systemId, '')
          break
      }
    },

    addSystemNote(header, systemId, text, save, afterLetter) {
      /*
          // add note to currentNotes - used when adding Custom Item and add Header
          // use updateNote to optionally save to quoteMaterialList state data
          header: {id: null || existing int, name: null || String}
          - header id increments to next if not provided which adds new header
          - header name updates exisiting within header if provided
          text adds new note obj to header if provided
          silent truthy prevents wait spinner blocking
        */

      let currentNotes = JSON.parse(JSON.stringify(this.currentNotes))
      let headerId = header && header.id ? header.id : null

      // if existing notes exist in this add within existing notes
      let existingHeaderIndex = currentNotes.findIndex(itm => {
        return itm.id == headerId
      })

      let headerNotes = []
      if (existingHeaderIndex !== -1) {
        headerNotes = currentNotes[existingHeaderIndex].notes || []
        // allow update header name
        if (header.name) {
          //  currentNotes[existingHeaderIndex].name = header.name;
        }
      } else {
        const prevHeaderId = this.getCenteredElement()

        if (prevHeaderId) {
          const index = currentNotes.findIndex(note => note.id === prevHeaderId)

          if (index !== -1) {
            const prevSort = +currentNotes[index].sort
            header = { id: header.id, name: header.name, notes: [], sort: prevSort + 1 }
            currentNotes.splice(index + 1, 0, header)

            currentNotes.slice(index + 2).forEach(note => (note.sort += 1))
          } else {
            currentNotes.push({ id: header.id, name: header.name, notes: [], sort: header.sort })
          }
        } else {
          currentNotes.push({ id: header.id, name: header.name, notes: [], sort: header.sort })
        }
      }

      let notes = [...headerNotes]

      // filter to system if system added
      if (systemId) {
        notes = notes.filter(itm => {
          return itm.si == systemId
        })
      }

      // make sure in letter sort order
      notes = notes.sort((a, b) => {
        if (a.sl < b.sl) return -1
        if (a.sl > b.sl) return 1
        return 0
      })

      let lastSortLetter = notes.length ? notes[notes.length - 1] && notes[notes.length - 1].sl : null
      if (lastSortLetter === 'z') {
        this.$snack.open('Maximum number of notes added for this System or Header', 'warning')
        return
      }

      let newNoteObj = null
      let nextSortLetter = afterLetter ? this.incrementLetter(afterLetter) : lastSortLetter ? this.incrementLetter(lastSortLetter) : 'a'
      if (text !== null) {
        newNoteObj = {
          si: systemId,
          t: text,
          sl: nextSortLetter
        }

        if (afterLetter) {
          const letterIndex = headerNotes.findIndex(itm => itm.sl === afterLetter && (!itm.si || itm.si == systemId))
          headerNotes.splice(letterIndex + 1, 0, newNoteObj)
        } else {
          headerNotes.push(newNoteObj)
        }

        setTimeout(() => {
          let n = null
          if (systemId) {
            n = document.querySelector('[data-h="' + headerId + '"] [data-s="' + systemId + '"][data-n="' + nextSortLetter + '"]')
          } else {
            n = document.querySelector('[data-h="' + headerId + '"] [data-n="' + nextSortLetter + '"]')
          }

          if (n && n.querySelector('.formatted-content')) {
            n.querySelector('.formatted-content').click()
          } else if (n) {
            n.focus()
          }
        }, 200)
      }

      for (let ani = 0; ani < currentNotes.length; ani++) {
        if (currentNotes[ani].id == headerId) {
          currentNotes[ani].notes = headerNotes
        }
      }
      this.currentNotes = currentNotes
      this.setNotes()

      if (save && newNoteObj) {
        this.updateNote(newNoteObj, headerId, true)
      }
    },

    updateNote(n, headerId, doSave) {
      // consider changing from directly saving the note objec to saving the note item from what is stored in currentNotes,
      // this way can possibly make sure header is saved as well incase it does not exist yet
      // retrieve n from currentNotes
      let currentNotes = this.currentNotes //JSON.parse(JSON.stringify(this.currentNotes));
      const headerIndex = currentNotes.findIndex(itm => {
        return itm.id == headerId
      })
      if (headerIndex === -1) return
      let headerObj = currentNotes[headerIndex]
      let headerNotes = headerObj.notes || []
      let currentNoteIndex = headerNotes.findIndex(itm => {
        if (n.sn) {
          return itm.sn == n.sn && itm.sl == n.sl
        }
        return itm.sl == n.sl
      })
      if (currentNoteIndex === -1) return

      delete this.currentNotes[headerIndex].notes[currentNoteIndex].editing

      // match or add header in quoteMaterialList
      let savedNotes = JSON.parse(JSON.stringify(this.committedNotes))
      if (
        !savedNotes.find(itm => {
          return itm.id == headerId
        })
      ) {
        const newHeader = {
          id: headerId,
          name: headerObj.name,
          sort: headerObj.sort
        }
        savedNotes.push(newHeader)
      }

      // add note to header
      for (let sni = 0; sni < savedNotes.length; sni++) {
        if (savedNotes[sni].id == headerId) {
          let notes = Array.isArray(savedNotes[sni].notes) ? savedNotes[sni].notes : []
          let savedNoteIndex = notes.findIndex(itm => {
            if (n.si) {
              return itm.si == n.si && itm.sl == n.sl
            }
            return itm.sl == n.sl
          })
          if (savedNoteIndex !== -1) {
            // resolve issue with this class getting saved
            n.t = n.t.replace(/ql-cell-selected/g, '').replace(/ql-cell-focused/g, '')
            savedNotes[sni].notes[savedNoteIndex].t = n.t
          } else {
            if (!Array.isArray(savedNotes[sni].notes)) {
              savedNotes[sni].notes = []
            }
            savedNotes[sni].notes.push(n)
          }
        }
      }

      this.committedNotes = savedNotes

      if (doSave) {
        this.saveNotes()
      }
    },

    saveAllNotes() {
      return new Promise((resolve, reject) => {
        const hasAuth = (this.userAuthedData || {}).eid || null
        if (this.readonly || !this.quoteMaterialListId || !hasAuth) {
          resolve()
          return
        }

        if (!this.hasUnsavedNotes() && !this.hasUnsavedChanges()) {
          resolve()
          return
        }

        // do not save if open in new window, will still allow autosave if in differnt tab
        if (this.isOpenInDifferentTab()) {
          resolve()
          return
        }

        this.$bus.$emit('saveAllQuoteWriteUpNotes') // saves to header child components
        setTimeout(() => {
          this.saveNotes().then(() => {
            this.lettersArray = {}
            resolve()
          })
        }, 500)
      })
    },

    saveNotes() {
      return new Promise((resolve, reject) => {
        const savedNotes = JSON.parse(JSON.stringify(this.committedNotes))
        const params = {
          action: 'update_quote_write_up_notes',
          id: this.quoteMaterialListId,
          system_notes: savedNotes
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Saving Notes' })

        appFuncs
          .shRequest(params)
          .then(data => {
            //  this.$snack.open('Updated', 'success');
            resolve()

            // decided to not update quoteMaterialList store after updating individual notes since anywhere that uses the note date will retreive updates from server. ie anytime this component loads - tab is switched
            // update quoteMaterialList store with notes
            // this.quoteMaterialList.materialList.system_notes = data.system_notes;
            // this.$store.commit('quoteMaterialList', this.quoteMaterialList);
          })
          .catch(data => {
            this.$snack.open(data.message || 'Problem saving notes.', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    setQuoteMaterialList() {
      this.currentNotes = JSON.parse(JSON.stringify(this.committedNotes))
      let systems = JSON.parse(JSON.stringify(((this.quoteMaterialList || {}).materialList || {}).systems || []))
      systems = Array.isArray(systems) ? systems : []
      systems = systems.sort((a, b) => {
        if (a.number < b.number) return -1
        if (a.number > b.number) return 1
        return 0
      })
      systems = systems.filter(itm => {
        return itm.id !== 'default'
      })
      this.systems = systems
      this.setNotes()
    },

    addCustomItemBelow(event, headerId) {
      let systemId = ((event || {}).n || {}).si
      let letter = ((event || {}).n || {}).sl
      if (headerId == 1) {
        this.addSystemNote({ id: headerId }, systemId, '', false, letter)
      } else {
        // dont add system id
        this.addSystemNote({ id: headerId }, null, '', false, letter)
      }
    },

    fixNoteLetters() {
      const currentNotes = JSON.parse(JSON.stringify(this.currentNotes))
      for (let i = 0; i < this.currentNotes.length; i++) {
        let notes = this.currentNotes[i].notes
        var lastLetter = null
        var lastSystem = ''

        for (let ni = 0; ni < notes.length; ni++) {
          let sys = notes[ni].si ? notes[ni].si : ''
          if (sys != lastSystem) {
            lastLetter = null
          }
          lastSystem = notes[ni].si ? notes[ni].si : ''

          lastLetter = this.incrementLetter(lastLetter)
          currentNotes[i].notes[ni].sl = lastLetter
        }
      }
      this.currentNotes = currentNotes
      this.committedNotes = currentNotes
    },

    saveTableNote(headerId, { content, doSave }) {
      let currentNotes = this.currentNotes
      const headerIndex = currentNotes.findIndex(itm => {
        return itm.id == headerId
      })
      if (headerIndex === -1) return
      let headerObj = currentNotes[headerIndex]
      this.currentNotes[headerIndex].table = content

      // add to committed notes
      let savedNotes = JSON.parse(JSON.stringify(this.committedNotes))

      // if new header does not exist create it
      if (
        !savedNotes.find(itm => {
          return itm.id == headerId
        })
      ) {
        const newHeader = {
          id: headerId,
          name: headerObj.name,
          sort: headerObj.sort
        }
        savedNotes.push(newHeader)
      }

      for (let sni = 0; sni < savedNotes.length; sni++) {
        if (savedNotes[sni].id == headerId) {
          savedNotes[sni].table = content
        }
      }
      this.committedNotes = savedNotes

      if (doSave) {
        this.saveNotes()
      }
    },

    getCenteredElement() {
      const elements = document.querySelectorAll('.header-notes-container')
      const viewportCenter = window.innerHeight / 2
      let closestElement = null
      let minDistance = Infinity

      elements.forEach(el => {
        const rect = el.getBoundingClientRect()
        const elementCenter = rect.top + rect.height / 2
        const distance = Math.abs(viewportCenter - elementCenter)

        if (distance < minDistance) {
          minDistance = distance
          closestElement = el
        }
      })

      if (closestElement) {
        const closestChild = closestElement.querySelector('.note-template-items')
        if (closestChild) {
          return closestChild.getAttribute('data-h')
        }
      }
      return false
    }
  },

  async mounted() {
    if (!this.quoteMaterialListId) {
      this.$router.push({ name: 'all-quotes' })
      return
    }

    this.getData(true)
    this.$bus.$on(['updatedQuoteMaterialList', 'resetQuoteMaterialList'], this.getData)
    this.$bus.$on(['quote-systems-updated', 'import-quote-system'], this.setQuoteMaterialList)

    window.addEventListener('beforeunload', this.windowCloseListener)
  },

  beforeDestroy() {
    this.$bus.$off(['updatedQuoteMaterialList', 'resetQuoteMaterialList'], this.getData)
    this.$bus.$off(['quote-systems-updated', 'import-quote-system'], this.setQuoteMaterialList)
    window.removeEventListener('beforeunload', this.windowCloseListener)
    window.removeEventListener('beforeunload', this.closePopoupWindowListner)
    clearInterval(this.windowCloseListener)

    // this.$bus.$off('userInactive', this.saveAllNotes)
    // this.saveAllNotes()

    if (this.openedWriteUpWindow) {
      // @TODO:
      // should do this but the confirmation needs to be in parent BOMAddEdit component to prevent close,
      // - do it there and check this compoent by ref to see if opened window is open, if yes then show warning check.

      // if (confirm('If you leave and the separate write up window is open, it will close. Are you sure?')) {
      this.openedWriteUpWindow.close()
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

h4 {
  text-decoration: underline;
  font-size: 18px;
}

.writeup-headers {
  position: sticky;
  top: 0;
  padding-top: 20px;
  z-index: 100;
  background: transparent;
  backdrop-filter: blur(4px);
}

.table-add-icon-button {
  border: 1px solid;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;

  &::before {
    line-height: 18px;
  }
}
</style>
